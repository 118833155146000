<style>
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }
    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
</style>
<template>
    <div class="ele-body">
        <el-card shadow="never">
            <el-form
                    ref="form"
                    :model="form"
                    :rules="rules"
                    label-width="82px">
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane v-for="(item, index) in editableTabs"
                             :key="item.name"
                             :label="item.title"
                             :name="item.name" style="margin-top: 22px;">
                    <div v-for="(info, i) in configlist" >
                        <el-form-item v-if="info.type == 'input'" :label="info.content" >
                            <el-col :span="18" >
                                <el-input v-model="info.val"  :placeholder="info.content " ></el-input>
                            </el-col>
                        </el-form-item>
                        <el-form-item v-if="info.type == 'textarea'" :label="info.content" >
                            <el-col :span="18" >
                                <el-input v-model="info.val"  :placeholder="info.content "  :rows="3" type="textarea"></el-input>
                            </el-col>
                        </el-form-item>
                        <el-form-item v-if="info.type == 'radio'" :label="info.content" >
                            <el-col :span="18" >
                                <el-radio-group v-model="info.val" >
                                    <el-radio label="1">是</el-radio>
                                    <el-radio label="0">否</el-radio>
                                </el-radio-group>
                            </el-col>
                        </el-form-item>
                        <el-form-item v-if="info.type == 'image'" :label="info.content" >
                            <el-col :span="18" >
                                <el-upload
                                        class="avatar-uploader"
                                        :action="$http.defaults.baseURL+'upload/image/module_upload/image'"
                                        :show-file-list="false"
                                        :on-success="($event) =>{handleAvatarSuccess($event,i)}"
                                        :before-upload="beforeAvatarUpload">
                                    <img v-if="info.val" :src="info.val" class="avatar">
                                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                </el-upload>
                            </el-col>
                        </el-form-item>
                        <el-form-item v-if="info.type == 'json'" :label="info.content" >
                            <el-form-item v-for="(vals, key) in info.val" :label="key" style="margin-bottom: 22px;">
                                <el-col :span="18" >
                                    <el-input  v-model='info.val[key]' :placeholder="key" ></el-input>
                                </el-col>
                            </el-form-item>
                        </el-form-item>
                        <el-form-item v-if="info.type == 'button'" >
                            <el-button type="primary" round   v-for="(vals, key) in info.val" @click="$router.push(vals.url)" >{{ vals.name }}</el-button>
                        </el-form-item>
                    </div>


                    <el-button style="float: right;"
                            type="primary"
                            @click="save"
                            :loading="loading">保存
                    </el-button>



                </el-tab-pane>
            </el-tabs>
            </el-form>
        </el-card>
    </div>
</template>

<script>
    export default {
        name: 'LevelEdit',
        props: {
            // 弹窗是否打开
            visible: true,
            // 修改回显的数据
            data: Object
        },
        data() {
            return {
                activeName: '0',
                editableTabs: [/*{
                    title: "网站配置",
                    content: 1
                },*/{
                    title: "短信配置",
                    content: 2
                },{
                    title: "前测提示",
                    content: 3
                },{
                  title: "讯飞配置",
                  content: 4
                },{
                  title: "推题模块数量配置",
                  content: 19
                }],
                form: Object.assign({}, this.data),
                isUpdate: false,
                rules: {
                    name: [
                        {required: true, message: '请输入等级', trigger: 'blur'}
                    ],
                },
                loading: false,
                configlist:[]
            };
        },
        mounted() {
            //this.tableTabs();
            this.handleClick({index:0,name:"1"});

        },
        methods: {
            save() {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        this.loading = true;
                        this.$http['post']('/Admin/configs', this.form).then(res => {
                            //console.log(res)
                            this.loading = false;
                            if (res.data.code === 200) {
                                this.$message({type: 'success', message: res.data.msg});
                                if (!this.isUpdate) {
                                    this.form = {};
                                }
                                this.updateVisible(false);
                                this.$emit('done');
                            } else {
                                this.$message.error(res.data.msg);
                            }
                        }).catch(e => {
                            this.loading = false;
                            this.$message.error(e.message);
                        });
                    } else {
                        return false;
                    }
                });
            },
            handleClick(tab, event) {
               // var index = +tab.index + 1; 去掉网站配置，index从2开始
                var index = +tab.index + 2;
                this.$http.get('/Admin/configs',{params:{group:index }}).then(res => {
                    this.configlist = res.data.data;
                    this.form = Object.assign({}, res.data.data);
                })
            },
            /* 更新visible */
            updateVisible(value) {
                this.$emit('update:visible', value);
            },
            handleAvatarSuccess(res, file) {
                this.configlist[file].val = process.env.VUE_APP_BASE_URL + res.data.path;

            },
            // 上传之前的回调
            beforeAvatarUpload(file) {
                const isJPG = file.type === 'image/jpeg';
                const isLt2M = file.size / 1024 / 1024 < 2;

                if (!isJPG) {
                    this.$message.error('上传图片只能是 JPG 格式!');
                }
                if (!isLt2M) {
                    this.$message.error('上传图片大小不能超过 2MB!');
                }
                return isJPG && isLt2M;
            },

        }
    };
</script>


